<template>
	<div class="live_count_cleaning">
		<e-table :tableCols="liveCountCleaningTableConf" :showOperation="false" :dataOrigin="dataOrigin">
			<template #customOperation>
				<el-col :span="24">
					<el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
						<el-radio-button label="1">待贷款</el-radio-button>
						<el-radio-button label="2">待查账</el-radio-button>
						<el-radio-button label="3">招商审核通过</el-radio-button>
						<el-radio-button label="4">已退还</el-radio-button>
						<el-radio-button label="5">已到账</el-radio-button>
					</el-radio-group>
				</el-col>
			</template>
		</e-table>
		<e-dialog title="" ref="dialogRef">
		</e-dialog>
	</div>
</template>

<script>
import {liveCountCleaningTableConf} from '@data/finance'
export default {
	name: 'liveCountCleaning',
	data() {
		return {
			liveCountCleaningTableConf,
			dataOrigin: {
				data: [
				]
			},
			tabPosition: 1
		}
	},
	methods: {
		handleClick() {}
	}
}
</script>